import React, { useState } from 'react';
import { Profile } from '../../../../../domain';
import { Control, UseFormClearErrors, UseFormSetError, useController } from 'react-hook-form';
import { NcSlideFormData, NcSlideshowFormData } from './NcSlideshowDetails';

import styles from './NcSlidesManager.module.scss';
import { Button } from 'reactstrap';
import _ from 'lodash';
import classNames from 'classnames';
import { NcSlideConfigTextInput } from '../../components/NcSlideConfigTextInput';
import { NcSlideConfigNumberInput } from '../../components/NcSlideConfigNumberInput';
import { NcSlideConfigToggle } from '../../components/NcSlideConfigToggle';
import { NcSlideCreateModal } from './NcSlideCreateModal';
import { NarrowcastingHelper } from '../../NarrowcastingHelper';
import { NcSlideTypeConfig } from './NcSlideTypeConfig';
import { NcSlidePreview } from './NcSlidePreview';
import { DragDropContext, Draggable, DraggableProvided, DraggableRubric, DraggableStateSnapshot, DropResult, Droppable, ResponderProvided } from 'react-beautiful-dnd';
import { ImmutArray } from '../../../../utils/ImmutablesHelper';
import { TippyReact } from '../../../../components';

interface Props {
  magazine: number,
  profile: Profile,
  control: Control<NcSlideshowFormData>,
  setError: UseFormSetError<NcSlideshowFormData>
  clearErrors: UseFormClearErrors<NcSlideshowFormData>

}

export const NcSlidesManager = ({ magazine, profile, control, setError, clearErrors }: Props) => {

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);

  const { field, fieldState } = useController({ control, name: 'slides' });

  const slides = _.sortBy(field.value, 'sort');

  const onDeleteSlide = (index: number) => {
    clearErrors(`slides.${index}`);
    field.onChange(_.compact(_.map(slides, (s, idx) => {
      if (idx < index) {
        return s;
      }
      if (idx === index) {
        return null;
      }
      return {
        ...s,
        sort: s.sort - 1
      }
    })))
    if (activeIndex >= index) {
      setActiveIndex(activeIndex > 0 ? activeIndex - 1 : 0);
    }
  }

  const isActiveIndexVideo = slides[activeIndex]?.type === 'video' ? true : false;

  const reorder = (listItems: NcSlideFormData[], sourceIndex: number, destinationIndex: number): NcSlideFormData[] => {
    const reorderedSlides = ImmutArray.move(listItems, sourceIndex, destinationIndex);
    return _.map(reorderedSlides, (s, index) => {
      return {
        ...s,
        sort: index
      }
    })
  }

  const onSlideDragEnd = (result: DropResult, provided: ResponderProvided) => {
    if (!result.destination) {
      return;
    }
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;
    const newListOfSlides = reorder(slides, sourceIndex, destinationIndex);
    field.onChange(newListOfSlides);
    setActiveIndex(destinationIndex);
  }

  const renderSortableSlideTile = (provided: DraggableProvided, snapshot: DraggableStateSnapshot, rubric: DraggableRubric) => {
    const s = slides[rubric.source.index];

    return (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        className={classNames({ [styles.isDragging]: snapshot.isDragging })}
        style={{ ...provided.draggableProps.style }}
      >
        <div className={classNames(styles.NcSlidesListItem, { [styles.activeItem]: s.sort === activeIndex })} >
          <div className={classNames(styles.NcSlideTile, { [styles.activeTile]: s.sort === activeIndex })} onClick={() => setActiveIndex(s.sort)}>
            <span {...provided.dragHandleProps} className={'material-icons'}>drag_indicator</span>
            <img className={styles.typeIcon} src={`/assets/icons/48/slideType-${s.type}.svg`} />
            <TippyReact config={{ disabled: s.config.title ? false : true }} content={s.config.title}>
              <div className={classNames(styles.text, { [styles.typeText]: s.config.title ? false : true })}>{s.config.title || NarrowcastingHelper.slideTypeToTitle(s.type)}</div>
            </TippyReact>
            {_.get(fieldState.error, `${rubric.source.index}`) && <i className={classNames(styles.hasErrorsIcon, 'fa', 'fa-exclamation-triangle')}></i>}
            <i className={classNames(styles.deleteIcon, 'fa', 'fa-trash')} onClick={(e) => {
              onDeleteSlide(rubric.source.index);
              e.preventDefault();
              e.stopPropagation();
              return false;
            }}></i>
          </div>
        </div>
      </div>
    )
  };

  return (
    <>
      <div className={styles.NcSlidesManager}>
        <div className={styles.NcSlidesOverviewListWrapper}>
          <div className={styles.sectionTitle}>
            slides
            {fieldState.error && <TippyReact content={`Check below for errors!`}><div className={classNames(styles.hasErrorsIcon)}><i className={classNames('fa fa-exclamation-triangle')}></i></div></TippyReact>}
          </div>
          <div className={styles.NcSlidesOverviewList}>
            <DragDropContext onDragEnd={onSlideDragEnd}>
              <Droppable
                droppableId='slidesDroppable'
                renderClone={renderSortableSlideTile}
              >
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef} style={{}}>
                    {_.map(slides, (s, index) => {
                      return (
                        <Draggable key={`s-${s.sort}`} draggableId={`s-${s.sort}`} index={index}>
                          {renderSortableSlideTile}
                        </Draggable>
                      )
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <div style={{ textAlign: 'center', marginTop: '16px' }}>
            <Button color={'secondary'} onClick={() => setIsCreateModalOpen(true)}>add new slide</Button>
          </div>
        </div>
        <div className={styles.NcSlideDetails}>
          {!_.isEmpty(slides) &&
            <>
              <div key={`slideConfig-${activeIndex}`} className={styles.NcSlideConfig}>
                <div className={styles.flexRow}>
                  <div style={{ flex: 1 }}>
                    <NcSlideConfigTextInput
                      {...{ field, activeIndex }}
                      fieldName={`title`}
                      label={`Slide name:`}
                      placeholder={`${_.capitalize(NarrowcastingHelper.slideTypeToTitle(slides[activeIndex].type))} slide name`}
                      maxLength={80}
                    />
                  </div>
                  {!isActiveIndexVideo &&
                    <div>
                      <NcSlideConfigNumberInput
                        {...{ field, activeIndex }}
                        fieldName={`duration`}
                        label={`Duration:`}
                        inputWrapperClass={styles.durationInput}
                        min={1}
                      />
                    </div>
                  }
                  <div style={{ minWidth: '82px' }}>
                    <NcSlideConfigToggle
                      {...{ field, activeIndex }}
                      fieldName={`showTicker`}
                      label={`Newsticker:`}
                    />
                  </div>
                </div>
                <NcSlideTypeConfig
                  {...{ magazine, profile, control, field, activeIndex, setError, clearErrors }}
                  type={slides[activeIndex].type}
                />
              </div>
              <div className={styles.NcSlidePreviewSection}>
                <div className={styles.sectionTitle}>preview</div>
                <div className={styles.previewContainerWrapper}>
                  <NcSlidePreview magazine={magazine} ncSlide={slides[activeIndex]} />
                </div>
              </div>
            </>
          }
        </div>
      </div >
      {isCreateModalOpen &&
        <NcSlideCreateModal
          {...{ control, setActiveIndex }}
          allowAdvancedSlideType={profile?.edConfig?.narrowcastingAdvancedSlideType?true:false}
          onClose={() => setIsCreateModalOpen(false)}
        />
      }

    </>
  )
}

