import React, { useEffect, useMemo } from 'react';
import styles from '../NcSlidesManager.module.scss';
import { NcSlideshowFormData } from '../NcSlideshowDetails';
import { ControllerRenderProps, UseFormClearErrors, UseFormSetError } from 'react-hook-form';
import _ from 'lodash';
import { Profile } from '../../../../../../domain';
import { TippyReact } from '../../../../../components';
import classNames from 'classnames';
import { NcSlideConfigJsonArea } from '../../../components/NcSlideConfigJsonArea';
import { JsonHelper } from '../../../../../utils';

interface Props {
  magazine: number,
  profile: Profile,
  field: ControllerRenderProps<NcSlideshowFormData, 'slides'>,
  activeIndex: number
  setError: UseFormSetError<NcSlideshowFormData>
  clearErrors: UseFormClearErrors<NcSlideshowFormData>
}

export const NcSlideTypeConfig_Advanced = ({ magazine, profile, field, activeIndex, setError, clearErrors }: Props) => {

  const slide = _.find(field.value, (s) => s.sort === activeIndex);
  const jsonStr = _.get(slide?.config, 'json');

  const readOnly = profile?.edConfig?.narrowcastingAdvancedSlideType?false:true;

  const isValidJson:boolean = useMemo(() => {
    if (!jsonStr || !JsonHelper.isValidJson(jsonStr)) {
      return false;
    } else {
      return true;
    }
  }, [jsonStr ]);

  useEffect(() => {
    if(!isValidJson){
      setError(`slides.${activeIndex}.config.json`, { type: 'advancedSlideValidation', message: 'No valid json for advanced slide' });
    }else{
      clearErrors(`slides.${activeIndex}.config.json`);
    }
  }, [isValidJson,activeIndex, setError, clearErrors])
  

  return (
    <>
      <div className={styles.flexRow}>
        <TippyReact content={readOnly && isValidJson?`This is read only, as advanced slide type has been disabled!`:`A valid JSON should be entered!`} config={{ placement: 'top', disabled: isValidJson && !readOnly ? true : false }}>

          <div style={{ flex: 1 }} className={classNames({ [styles.requiredValueMissing]: !isValidJson ? true : false, [styles.readOnly]: readOnly})}>
            <NcSlideConfigJsonArea
              {...{ field, activeIndex }}
              fieldName={`json`}
              label={`Advanced JSON configuration:`}
              placeholder={`Advanced JSON configuration`}
              readOnly={readOnly}
            />
          </div>
        </TippyReact>
      </div>
    </>
  )
};
