import React from 'react';
import { ControllerRenderProps, UseFormClearErrors, UseFormSetError } from 'react-hook-form';
import { NcSlideshowFormData } from './NcSlideshowDetails';

import styles from './NcSlidesManager.module.scss';
import { NarrowcastingHelper } from '../../NarrowcastingHelper';
import _ from 'lodash';
import { NcSlideTypeConfig_Image } from './slideTypes/NcSlideTypeConfig_Image';
import { Profile } from '../../../../../domain';
import { NcSlideTypeConfig_Article } from './slideTypes/NcSlideTypeConfig_Article';
import { NcSlideTypeConfig_Video } from './slideTypes/NcSlideTypeConfig_Video';
import { NcSlideTypeConfig_Weather } from './slideTypes/NcSlideTypeConfig_Weather';
import { NcSlideTypeConfig_Advanced } from './slideTypes/NcSlideTypeConfig_Advanced';

interface Props {
  magazine: number,
  profile: Profile,
  field: ControllerRenderProps<NcSlideshowFormData, 'slides'>,
  activeIndex: number
  type: string
  setError: UseFormSetError<NcSlideshowFormData>
  clearErrors: UseFormClearErrors<NcSlideshowFormData>

}

export const NcSlideTypeConfig = ({ magazine, profile, field, activeIndex, type, setError, clearErrors }: Props) => {
  switch (type) {
    case 'image':
      return <NcSlideTypeConfig_Image {...{ magazine, profile, field, activeIndex, setError, clearErrors }} />
    case 'video':
      return <NcSlideTypeConfig_Video {...{ magazine, profile, field, activeIndex, setError, clearErrors }} />
    case 'weather':
      return <NcSlideTypeConfig_Weather {...{ magazine, profile, field, activeIndex, setError, clearErrors }} />
    case 'imgzine-articles':
      return <NcSlideTypeConfig_Article {...{ magazine, profile, field, activeIndex, setError, clearErrors }} />
    case 'advanced':
      return <NcSlideTypeConfig_Advanced {...{ magazine, profile, field, activeIndex, setError, clearErrors }} />
    default:
      return <div className={styles.flexRow}><div style={{ fontStyle: 'italic' }}>{_.capitalize(NarrowcastingHelper.slideTypeToTitle(type))} type is not fully supported yet</div></div>
  }
}
