import React, { Component, Fragment } from 'react';
import { Profile, StatsFilter } from '../../../../../domain';
import {  Col, Alert } from 'reactstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import '../../../../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import * as Api from '../../../../api';

import './css/AllArticlesStats.min.css';
import { LanguageContextConsumer } from '../../../../LanguageContext';
import { Translate } from '../../../../translations';
import * as _ from 'lodash';
import { EdErrorHandler, OrtecLoader } from '../../../../widgets';
import { ChannelsSelect } from '../../../../components';
import { Channel } from '../../../../components/ChannelsSelect/ChannelsSelect';
import { ArticleHelper, DateHelper } from '../../../../utils';

//
// ─── ENABLE TRANSLATIONS FIXED CODE ─────────────────────────────────────────────
//
let Language = 'en';
const enableTranslations = () => {
  return (
    <LanguageContextConsumer>
      {languageContext => { Language = languageContext ? languageContext.language : 'en'; return '' }}
    </LanguageContextConsumer>
  )
}
// ────────────────────────────────────────────────────────────────────────────────

const requestTitlesFor: number[] = [];

interface ArticleLimitReachedWarning {
  maxArticlesLimit:number,
  totalArticles:number,
}

export interface Props {
  magazine: string,
  profile: Profile,
  statsFilter: StatsFilter,
  clickArticleHandler: (article: number) => void
}

export interface State {
  articles?: any[],
  channels?: Channel[],
  allChannelArticles?: any[],
  filtering?: boolean,
  articleLimitReachedWarning?: ArticleLimitReachedWarning
}


export class AllArticlesStats extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    // this.setState({sortBy:'views',sortOrder:'desc'});
    this.calcChartStats('allArticles');
    this.getMagazineChannels();
    // this.calcChartStats('topArticles_time');
    // this.calcChartStats('topArticles_likes');

  }
  getMagazineChannels = async () => {
    try {
      const resp = await Api.getMagazineChannels(parseInt(this.props.magazine));
      this.setState({ channels: resp.data.channels });
    } catch (error) {
      EdErrorHandler(error, 'getting magazine channels');
      this.setState({ channels: [] })
    }
  }



  calcChartStats(chart: string) {
    // const statsFilter = {...this.props.statsFilter};
    Api.loadChartData(chart, this.props.statsFilter, this.props.magazine).then((resp) => {
      if (!resp.data || resp.data.error) {
        console.log('Error in getting chart data');
        this.setState({ articles: [], allChannelArticles: [] });
      }
      // console.log(resp.data);
      const articles = resp.data.articles ? this.normalizeData(resp.data.articles) : [];
      this.setState({ articles: articles, allChannelArticles: [...articles], articleLimitReachedWarning: resp.data.articleLimitReachedWarning });
    })
      .catch((error) => {
        console.log('An error occured:' + error.message)
      });
  }
  normalizeData(articles: any[]) {
    return articles.map((a) => {
      return {
        ...a.value,
        id: a.article,
        title: a.title,
        currentComments: a.comments,
        date: a.cdate,
        end: a.end,
      }
    })
  }
  timeFormatter(cell: any, row: any, extraData: any) {
    // const val = cell[extraData.field];
    const val = cell;
    const secs = Math.ceil(val ? val : 0);
    const minutes = Math.floor(secs / 60);
    const seconds = Math.round(secs - minutes * 60);
    return minutes + 'm' + ' ' + seconds + 's';
  }
  onRowClick(row: any) {
    this.props.clickArticleHandler(row.id);
  }

  commentsFormatter(cell: any, row: any, extraData: any) {
    // return `${cell?cell:'-'} | ${row.currentComments?row.currentComments:'-'}`;
    // return (<div className="commentsDiv"><span>{cell?cell:'-'}</span> <span className="currentComments"> ({row.currentComments?row.currentComments:'-'})</span></div>)
    return (<span className="currentComments" data-tippy-content={"Current comments"}> ({row.currentComments ? row.currentComments : '-'})</span>)
  }

  valueFormatter(cell: any, row: any, extraData: any) {
    return <>{cell ? cell : '-'}</>;

  }
  viewsFormatter(cell: any, row: any, extraData: any) {
    return (<span data-tippy-content={row.end ? `Last viewed on: ${this.dateFormatter(row.end, row, extraData)}` : ''}>{cell ? cell : '-'}</span>);
  }
  dateFormatter(cell: any, row: any, extraData: any) {
    return <>{cell ? DateHelper.dateTimeToLocaleString(cell) : '-'}</>;
  }
  titleFormatter(cell: any, row: any, extraData: any) {
    if (cell === '-') {
      if (_.includes(requestTitlesFor, row.id)) {
        return <>{cell}</>;
      } else {

        requestTitlesFor.push(row.id);
        Api.getArticleTitle(parseInt(this.props.magazine), row.id).then((resp) => {
          if (!resp.data || resp.data.error) {
            console.log(`Error in getting article title: ${resp.data.error}`);
            return;
          }
          const title = _.get(resp.data, 'title', null);
          if (title) {
            this.changeArticleTitle(resp.data.id, title);
          }
        })
          .catch((error) => {
            console.log(`Error: ${error.response.data.error}`);
            this.changeArticleTitle(row.id, '-');
          });

        return (<i className="fa fa-spinner fa-spin"></i>);

      }

    }
    return (<div className='ellipsisDiv'>{ArticleHelper.convertToReadableTitle(cell)}</div>);
  }
  goToPage() {

  }
  renderPaginationPanel(props: any) {
    let max = 1;
    let min = 1;
    if (this.state.articles) {
      max = (this.state.articles.length / props.sizePerPage) + 1;
    }
    const trt = Translate(Language, 'Tables');
    return (
      <Fragment>
        <Col md={8} className='dropup'>{props.components.pageList} {props.components.sizePerPageDropdown}</Col>
        <Col md={4}>
          <div className="goToPageDiv">
            <input id="goToPageInput" onKeyDown={(e: any) => {
              if (e.keyCode === 13) {
                let val = e.target.value;
                if (val < min) {
                  val = min;
                } else if (val > max) {
                  val = max;
                }
                props.changePage(val);
                e.target.value = '';
              }
            }} className='form-control' type="number" placeholder={trt('go_to_page')} style={{ width: 'auto' }} />
            <button className="btn" onClick={(e) => {
              var k: any = document.getElementById('goToPageInput');
              let val = k.value;
              if (val < min) {
                val = min;
              } else if (val > max) {
                val = max;
              }
              props.changePage(val);
              k.value = ''
            }}>{'>'}</button>
          </div>
        </Col>
      </Fragment>
    );
  }

  changeArticleTitle(id: number, title: string) {
    const { articles } = this.state;
    const article = _.find(articles, ['id', id]);
    article.title = title;
    this.setState({ articles: articles });

  }
  changeChannel = async (id: string) => {
    this.setState({ filtering: true });
    const val = id; //e.target.value;
    const { allChannelArticles } = this.state;
    if (!val) {
      this.setState({ articles: allChannelArticles ? [...allChannelArticles] : [], filtering: false });
      return;
    }
    try {

      const resp = await Api.getArticleIdsInChannel(parseInt(this.props.magazine), parseInt(val));
      const ids = _.map(resp.data.articles, _.toNumber);
      const filteredArticles = _.filter(allChannelArticles, (a) => _.includes(ids, a.id));
      this.setState({ articles: filteredArticles });
    } catch (error) {
      EdErrorHandler(error, `in getting article ids inside channel ${val}`);
    } finally {
      this.setState({ filtering: false });
    }
  }
  renderToolbar(props: any) {
    const { channels } = this.state;
    const { profile } = this.props;
    const tr = Translate(Language, 'ArticleSelector');

    return (
      <Fragment>
        <Col md={4}>{props.components.searchField}</Col>
        <Col md={4}></Col>
        <Col md={4}>
          <ChannelsSelect
            channels={channels ?? []}
            selected={''}
            onChange={this.changeChannel.bind(this)}
            placeholder={tr('allChannels')}
            channelGraphName={profile.edConfig?.channelGraphName}
          />
          {/* <div className='channelFilterContainer'>
            <Input type="select" onChange={this.changeChannel.bind(this)}>
              <option value=''>{tr('allChannels')}</option>
              {!channels?'':
               channels.map((c)=>{
                return (<option key={c.channel} value={c.channel}>{c.label?c.label:c.name}</option>);
               })
              }
            </Input>
          </div> */}
        </Col>
      </Fragment>
    );
  }
  render() {
    const { magazine, statsFilter } = this.props;
    const { filtering } = this.state;
    // const loader = <div className="loaderContainer"><div className="loaderWrapper"><i className="fa fa-spinner fa-spin"></i></div></div>
    const loader = <OrtecLoader />;
    const articles = this.state.articles;//this.getTopArticlesList();
    const tr = Translate(Language, 'Metrics');
    const trt = Translate(Language, 'Tables');
    const options: any = {
      defaultSortName: 'views',
      defaultSortOrder: 'desc',
      onRowClick: this.onRowClick.bind(this),
      // paginationShowsTotal:true,
      toolBar: this.renderToolbar.bind(this),
      paginationPanel: this.renderPaginationPanel.bind(this),
      sizePerPage: 25,
      sizePerPageList: [
        // {text:`10 ${trt('entries')}`,value: 10},
        { text: `25 ${trt('entries')}`, value: 25 },
        { text: `50 ${trt('entries')}`, value: 50 },
        { text: `100 ${trt('entries')}`, value: 100 },
        // {text:'All entries',value: articles?articles.length:0},
      ]
    }


    return (
      <div id="AllArticlesStats" className="">
        <div className="tableDiv withLoader">
          {enableTranslations()}
          {articles ?
            <div style={{ position: 'relative' }}>
              {this.state.articleLimitReachedWarning && 
              <Alert color="info">
                There are too many articles in the selected period. To maintain performance, we show the top {this.state.articleLimitReachedWarning.maxArticlesLimit} articles (in terms of article views) out of {this.state.articleLimitReachedWarning.totalArticles} articles.
              </Alert>
                }
              <BootstrapTable ref="topArticlesTable" data={articles} bordered={false} hover={true} version='4' options={options} pagination search >
                <TableHeaderColumn isKey dataField='id' dataSort={true} hidden></TableHeaderColumn>
                <TableHeaderColumn dataField='title' dataFormat={this.titleFormatter.bind(this)} dataSort={false} tdStyle={{ cursor: 'pointer' }}></TableHeaderColumn>
                <TableHeaderColumn dataField='date' dataFormat={this.dateFormatter.bind(this)} dataSort={true} dataAlign='center' width='150px'>
                  <img data-tippy-content={trt("created")} src="/assets/icons/16/calendar.svg" />
                </TableHeaderColumn>
                <TableHeaderColumn dataField='viewers' dataFormat={this.valueFormatter.bind(this)} dataSort={true} dataAlign='center' width='60px'>
                  <img data-tippy-content={tr("users")} src="/assets/icons/16/users.svg" />
                </TableHeaderColumn>
                <TableHeaderColumn dataField='views' dataFormat={this.viewsFormatter.bind(this)} dataSort={true} dataAlign='center' width='100px'>
                  <img data-tippy-content={tr("article_views")} src="/assets/icons/16/views.svg" />
                </TableHeaderColumn>
                <TableHeaderColumn dataField='avgTime' dataFormat={this.timeFormatter.bind(this)} dataSort={true} dataAlign='center' width='70px'>
                  <img data-tippy-content={tr("avg_reading_time")} src="/assets/icons/16/time.svg" />
                </TableHeaderColumn>
                <TableHeaderColumn dataField='likes' dataFormat={this.valueFormatter.bind(this)} dataSort={true} dataAlign='center' width='50px'>
                  <img data-tippy-content={tr("likes")} src="/assets/icons/16/smiles.svg" />
                </TableHeaderColumn>
                <TableHeaderColumn dataField='shares' dataFormat={this.valueFormatter.bind(this)} dataSort={true} dataAlign='center' width='50px'>
                  <img data-tippy-content={tr("shares")} src="/assets/icons/16/shares.svg" />
                </TableHeaderColumn>
                <TableHeaderColumn dataField='comments' dataFormat={this.valueFormatter.bind(this)} dataSort={true} dataAlign='center' width='50px'>
                  <img data-tippy-content={trt("comments")} src="/assets/icons/16/comments.svg" />
                </TableHeaderColumn>
                {/* <TableHeaderColumn dataField='currentComments' dataFormat={this.commentsFormatter.bind(this)} dataSort={true} dataAlign='left' width='50px'>
                    <img data-tippy-content={"Current comments"} src="/assets/icons/16/comments.svg"/>
                  </TableHeaderColumn> */}
              </BootstrapTable>
              {!filtering ? null : <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}><OrtecLoader size='medium' /></div>}
            </div>
            : loader
          }
          {/* <div className="tableFooterDiv"><Button>view more</Button></div> */}
        </div>

      </div>
    );
  }
}

export default AllArticlesStats;
